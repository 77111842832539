import React from 'react';
import { CheckCard } from '@ant-design/pro-components';
import { Flex, Typography,Avatar } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';

const { Title, Link } = Typography;
const Mcplis = () => (

  <>
    <Title>{window.location.href}</Title>


    <Link href="/docs/spec/proximity-cn">设计原则</Link>


    <Flex gap={24} vertical>
      <CheckCard.Group

        onChange={(value) => {
          console.log('value', value);
        }}
      >
        
         <CheckCard
          avatar={
            <Avatar
              src="https://gw.alipayobjects.com/zos/bmw-prod/d12c3392-61fa-489e-a82c-71de0f888a8e.svg"
              size="large"
            />
          }
           title={ 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AppstoreOutlined />
              <span style={{ marginInlineEnd: 8, marginInlineStart: 8 }}>示例</span>
            </div> 
          }
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="A"
        />

        
        
        <CheckCard
        avatar="https://gw.alipayobjects.com/zos/bmw-prod/f601048d-61c2-44d0-bf57-ca1afe7fd92e.svg"
           title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AppstoreOutlined />
              <span style={{ marginInlineEnd: 8, marginInlineStart: 8 }}>示例</span>
            </div>
          }
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="B"
        />
        <CheckCard
        avatar="https://gw.alipayobjects.com/zos/bmw-prod/f601048d-61c2-44d0-bf57-ca1afe7fd92e.svg"
          title="Card c"
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="c"
        />
        <CheckCard
           title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AppstoreOutlined />
              <span style={{ marginInlineEnd: 8, marginInlineStart: 8 }}>示例</span>

            </div>
          }
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="d"
        />
         <CheckCard
          title="Card e"
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="e"
        />
         <CheckCard
          title="Card f"
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="f"
        />
         <CheckCard
          title="Card g"
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="g"
        />
         <CheckCard
          title="Card h"
          description="选择一个由流程编排提供的典型用户案例，可以从中学习到流程编排很多设计理念"
          value="h"
        />


      </CheckCard.Group>

      
    </Flex>


  </>


);
export default Mcplis