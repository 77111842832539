import React from 'react';
import { Empty } from 'antd';
import './index.scss';

const Dkf = () => {

 

 
  return <>

    <div className='fbody'>
      <div className='fbodym'>
        <Empty></Empty>



        


        
      </div>
    </div>

  </>


}

export default Dkf