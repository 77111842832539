import Am from '@/pages/Am'

import { createBrowserRouter } from 'react-router-dom'
import { AuthRoute } from '@/components/AuthRoute'
import { Auth } from '@/components/Auth'
import { AuthAlpha } from '@/components/AuthAlpha'

import Mp0 from '@/pages/Atest/Mp0'
import Mp1 from '@/pages/Atest/Mp1'
import Mp2 from '@/pages/Atest/Mp2'

import Test from '@/pages/Atest/Test'
import Test1 from '@/pages/Atest/Test1'
import Test2 from '@/pages/Atest/Test2'
import Test3 from '@/pages/Atest/Test3'


import Nqx from '@/pages/Nqx'
import NotFound from '@/pages/NotFound'
import Fbody from '@/pages/Fbody'

import Mcplis from '@/pages/Amb/Mcplis'

import Dkf from '@/pages/Amb/Dkf'
import M2d1 from '@/pages/Amb/M2d1'
import M2d2 from '@/pages/Amb/M2d2'
import M2d3 from '@/pages/Amb/M2d3'
import M2d4 from '@/pages/Amb/M2d4'

import M2d6 from '@/pages/Amb/M2d6'
import M2d61 from '@/pages/Amb/M2d61'
import M2d62 from '@/pages/Amb/M2d62'

import M3c1 from '@/pages/Amb/M3c1'
import M3c2 from '@/pages/Amb/M3c2'
import M3c3 from '@/pages/Amb/M3c3'

import M4z1 from '@/pages/Amb/M4z1'
import M4z2 from '@/pages/Amb/M4z2'

import M5y1 from '@/pages/Amb/M5y1'
import M5y2 from '@/pages/Amb/M5y2'
import M5y3 from '@/pages/Amb/M5y3'

import System01 from '@/pages/Sysp/System01'
import System02 from '@/pages/Sysp/System02'
import System03 from '@/pages/Sysp/System03'
import System04 from '@/pages/Sysp/System04'

import Abm from '@/pages/Abm'
import Ahelp from '@/pages/Ahelp'

const router = createBrowserRouter([
    {
        path: '/m',
        element: <AuthRoute> <Abm /> </AuthRoute>,
        children: [
            { path: '', element: <><Auth /> <Mcplis /></> },
            { path: 'M2d1', element: <><Auth /> <M2d1 /></> },
            { path: 'M2d2', element: <><Auth /> <M2d2 /></> },
            { path: 'M2d3', element: <><Auth /> <M2d3 /></> },
            { path: 'M2d4', element: <><Auth /> <M2d4 /></> },
            { path: 'M2d5', element: <><Auth /> <M2d3 /></> },
            { path: 'M2d6', element: <><Auth /> <M2d6 /></> },
            { path: 'M2d61', element: <><Auth /> <M2d61 /></> },
            { path: 'M2d62', element: <><Auth /> <M2d62 /></> },
            { path: 'M2d7', element: <><AuthAlpha /> <M2d3 /></> },
            { path: 'M3c1', element: <><Auth /> <M3c1 /> </> },
            { path: 'M3c2', element: <><Auth /> <M3c2 /> </> },
            { path: 'M3c3', element: <><Auth /> <M3c3 /> </> },
            { path: 'M4z1', element: <><Auth /> <M4z1 /></> },
            { path: 'M4z2', element: <><Auth /> <M4z2 /></> },
            { path: 'M4z3', element: <><Auth /> <Mp2 /></> },
            { path: 'M4z4', element: <><Auth /> <Mp2 /> </> },
            { path: 'M5y1', element: <><Auth /> <M5y1 /> </> },
            { path: 'M5y2', element: <><Auth /> <M5y2 /> </> },
            { path: 'M5y3', element: <><Auth /> <M5y3 /> </> },
            { path: 'system01', element: <><Auth /> <System01 /> </> },
            { path: 'system02', element: <><Auth /> <System02 /> </> },
            { path: 'system03', element: <><Auth /> <System03 /> </> },
            { path: 'system04', element: <><Auth /> <System04 /> </> },
            { path: 'index', element: <><Auth /><Mp0 /> </> },
            { path: 'mp2', element: <><Auth /><Mp2 /> </> },
            { path: 'no_access', element: <><Auth /><Nqx></Nqx></> },
            { path: 'mp1', element: <><Auth /><Mp1></Mp1></> }
        ]
    },
    {
        path: '/', element: <Am />, children: [
            { path: '', element: <Fbody /> },
        ]
    },
    
    {
        path: '/v3', element: <Abm />, children: [
            { path: '', element: <Mcplis /> },
            { path: 'pdt', element: <><Auth /> <M2d3 /></>},
            { path: 'pdt/sup', element: <><Auth /> <M2d3 /></>},
            { path: 'pdt/sup/intro', element: <><Auth /> <M2d1 /></>},
            { path: 'pdt/sup/plist', element: <><Auth /> <M2d3 /></>},
            { path: 'pdt/redb', element: <><Auth /> <M2d4 /></>},
            { path: 'pdt/adb', element: <><Auth /> <M2d4 /></>},
            { path: 'uc/un/uname', element: <><Auth /> <M4z2 /></>},

            { path: 'uc/oai', element: <><Auth /> <M3c1 /></>},
            { path: 'uc/oai/cz', element: <><Auth /> <M3c1 /></>},
            { path: 'uc/oai/xf', element: <><Auth /> <M3c2 /></>},
            { path: 'uc/oai/kp', element: <><Auth /> <M3c3 /></>},

            { path: 'tc/tz', element: <><Auth /><Mp1></Mp1></>},
            { path: 'tc/tp/p1', element: <><Auth /><Test /></>},
            { path: 'tc/tp/p2', element: <><Auth /><Test1 /></>},
            { path: 'tc/tp/p3', element: <><Auth /><Test2 /></>},
            { path: 'tc/lst', element: <><Auth /><Test3 /></>},

            { path: 'sys', element: <><Auth /><Test3 /></>},
            { path: 'sys/tcset', element: <><Auth /><Test3 /></>},

        ]
    },
    { path: '/help', element: <><Ahelp /> </> },
    { path: '/x', element: <Dkf /> },
    { path: '*', element: <NotFound /> }
])
export default router