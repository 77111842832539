import { Button, Form, Input, message } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { request } from '@/utils'
import { useNavigate } from 'react-router-dom';
import md5 from "js-md5";

const Apwjreg = () => {

    const navigate = useNavigate()
    const [sentc, setsentc] = useState("发送验证码"); // 
    const [zdzt, setzdzt] = useState(true);

    const onFinish = async (values) => {


        const md5psw1 = md5(values['password']);
        const o1 = { password: md5psw1 };
        const values1 = Object.assign(values, o1);
        const md5psw2 = md5(values['password2']);
        const o2 = { password2: md5psw2 };
        const promoterz = localStorage.getItem('promoter') ? localStorage.getItem('promoter') : "nopromoter";
        const o3 = { promoter: promoterz }
        const values2 = Object.assign(values1, o2);
        const valuese = Object.assign(values2, o3);


        const res = await request.post('/reg', valuese)

        if (res.return_code === "success") {
            message.success(res.return_msg)
            navigate('/')
        }
        if (res.return_code === "F") {
            message.error(res.return_msg)
            navigate('/')
        }



    }
    const [reg] = Form.useForm();
    const nameValue = Form.useWatch('username', reg);
    const scoden = { phone: nameValue, useto: 'reg' }


    const timeRef = useRef()//设置延时器
    const [time, setTime] = useState(null)//倒计时时间
    useEffect(() => {
        async function getau() {


            if (time && time !== 0) {
                timeRef.current = setTimeout(() => {
                    setTime(time => time - 1)
                    if (time <= 58) { setzdzt(false) }
                    if (time >= 2) {
                        setsentc((time - 1) + "秒后重发")
                        // console.log("zt=" + zdzt)

                    }
                    if (time === 1) {
                        setsentc("发送验证码")
                        setzdzt(true)
                        // console.log("zt=" + zdzt)
                    }


                }, 1000)

            }

        }

        getau()
        return () => {
            clearTimeout(timeRef.current)

        }
    }, [time])



    const sentcode =
        async () => {

            if (zdzt === true) {
                setTime(60)
                setsentc("60秒后重发")
                const res = await request.post('/sentcode', scoden)
                console.log("yzm" + JSON.stringify(res))
                if (res.return_code === 'success') {
                    reg.setFieldsValue({ sentcodeid: res.ddno });// 回传发送短信订单号码
                }


            }


        }


    return <>

        <Form onFinish={onFinish}
            validateTrigger="onBlur"
            form={reg}
        >
            <Form.Item
                name="username"
                rules={[
                    { required: true, message: '请输入用户名!' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号!' }



                ]}
            >
                <Input placeholder="请输入用户名!" autoComplete="true" />
            </Form.Item>



            <Form.Item

                style={{
                    marginBottom: 0,
                }}
            >
                <Form.Item
                    name="yzcode"
                    style={{
                        display: 'inline-block',
                        width: 'calc(50% - 8px)',
                    }}
                >
                    <Input placeholder="请输入验证码" />
                </Form.Item>
                <Form.Item
                    name="sentbutton"
                    style={{
                        display: 'inline-block',
                        width: 'calc(50% - 8px)',
                        margin: '0 8px',
                    }}
                >
                    <Button type='primary' style={{ width: '189px' }}
                        onClick={sentcode} >{sentc}</Button>


                </Form.Item>
            </Form.Item>


            <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
            >
                <Input.Password

                    type="password"
                    placeholder="请输入密码!"
                    autoComplete="true"
                />
            </Form.Item>

            <Form.Item
                name="password2"
                dependencies={['password']}
                rules={[
                    {
                        required: true, message: '请输入确认密码!'
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('密码不一致!'));
                        },
                    }),
                ]}

            >
                <Input.Password

                    type="password"
                    placeholder="请输入确认密码!"
                    autoComplete="true"
                />
            </Form.Item>
            <Form.Item
                name="sentcodeid"
                hidden={true}
            >
                <Input
                    autoComplete="true"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary"
                    htmlType="submit"
                    block={true} >
                    注   册
                </Button>
                {/* <a href="">用户注册</a> */}
            </Form.Item>
        </Form>




    </>
}

export default Apwjreg