import React, { useEffect } from 'react';
import { Layout, Divider } from 'antd';
import { Outlet, useSearchParams, useNavigate } from 'react-router-dom';
import Fhead from './_head';


import './index.scss';

const { Header, Content } = Layout;
const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    backgroundColor: '#fafafa',
    minHeight: '200px',

};

const footerStyle = {
    fontSize: '1.3em',
    paddingTop: '10px',
    textAlign: 'center',
    color: '#434343',
    backgroundColor: '#ffffff',
    bottom: 0,
    lineHeight: '24px',
};



const Am = () => {

    const navigate = useNavigate()
    const [tno] = useSearchParams()
    const tn = tno.get("t")
    useEffect(() => {
        if (tn !== null) {
            localStorage.setItem('promoter', tn)//把推广者Id写入 方便调用
            navigate('/') //隐藏推广链接
        }
    }, [navigate, tn])

    return <>

        <Header style={headerStyle}><Fhead /></Header>
        <Content style={contentStyle}><Outlet></Outlet></Content>

        <div style={footerStyle} >
            <Divider />
            郑州胜聪电子科技有限公司©2025  <a target='blank' href='https://beian.miit.gov.cn/' style={{ color: '#383838', textdecoration: "none" }} >豫ICP备13017074号</a>
        </div>


    </>

}
export default Am