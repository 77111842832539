import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { request } from '@/utils'
import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Divider, message } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { Editor, Toolbar } from '@wangeditor/editor-for-react'


function M2d61() {
    const token = localStorage.getItem('token_key')
    const [urlp] = useSearchParams()
    const [messageApi, contextHolder] = message.useMessage();
    const [fm] = Form.useForm();

    const onFinish = async (values) => {
        const resc = await request.post('/pms_style1_upxx', values)
        messageApi.open({
            type: resc.return_code,
            content: resc.return_msg,
        });

    };



    // editor 实例



    const [editor, setEditor] = useState(null)                   // JS 语法

    // 编辑器内容
    const [html, setHtml] = useState('暂无信息')

    // 模拟 ajax 请求，异步设置 html



    useEffect(() => {
        async function getau() {
            const res = await request.post('/pms_style1_c', { pno: urlp.get('q') })
           res.return_code==='success' && setHtml(res.json.cpxq)
           res.return_code==='success' && fm.setFieldValue( 'pno',  urlp.get('q') )
        }
        getau()
    }, [urlp,fm])




    // 工具栏配置
    const toolbarConfig = {}                        // JS 语法

    // 编辑器配置
    const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
        MENU_CONF: {}
    }
    editorConfig.MENU_CONF['uploadImage'] = { server: 'http://api.ddspp.com:30123/v3/?t=up_img&token=' + token, }
    editorConfig.MENU_CONF['uploadVideo'] = {
        server: 'http://api.ddspp.com:30123/v3/?t=up_video&token=' + token,
        maxFileSize: 500 * 1024 * 1024, //  允许最大视频大小500M
    }
    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)

        }
    }, [editor, setEditor])

    return (
        <>
            {contextHolder}
            <div style={{ border: '1px solid #ccc', zIndex: 100 }}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{
                        borderBottom: '1px solid #ccc'
                    }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {
                        setHtml(editor.getHtml())
                        fm.setFieldValue( 'cpxq', editor.getHtml() )

                    }

                    }
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>

            <Divider />

            <Form
                name="fm"
                form={fm}
                onFinish={onFinish}
                colon={false}
            >
                <Form.Item
                    name="cpxq"
                    // hidden={true}
                >
                    <Input autoComplete="true"
                    />
                </Form.Item>
                <Form.Item
                    // hidden={true} 
                    name='pno'  >
                    <Input />
                </Form.Item>

                <Form.Item label="">
                    <Button type="primary" htmlType="submit">
                        提 交
                    </Button>
                </Form.Item>
            </Form>


            {/* { html } 
            <div dangerouslySetInnerHTML={{ __html: html }}></div> */}

        </>
    )
}

export default M2d61