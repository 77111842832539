import React, { useEffect, useState } from 'react';
import { Typography, Descriptions } from 'antd';
import { request } from '@/utils'
import Apay from '@/pages/Amb/Apay';

const { Paragraph } = Typography;

const Zja = () => {






  const [bangm, setbangm] = useState([]);

  useEffect(() => {
    async function getuserinfo() {
      const res = await request.post('/authalpha')
      setbangm(res)

    }
    // setInterval(()=>{ getuserinfo()},32000)
    getuserinfo()

  }, [])

  const items = [
    {
      label: '账号',
      children: bangm.u_name,
    },
    {
      label: '联系电话',
      children: bangm.u_phone,
    },
    {
      label: '钱包余额',
      children: <span>￥{bangm.u_qb * 10 / 10}</span>,
    },
    {
      label: '推广链接',
      children: <Paragraph copyable>{'http://ddspp.vip/?t=' + bangm.uid}</Paragraph>,
    },


  ];

  return (<Descriptions items={items} />);
};
export { Zja, Apay }