/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Table, ConfigProvider, Drawer, Button, Select, message, Tag, Space, Tooltip, notification, Modal, Flex,Spin  } from 'antd';
import { SearchOutlined, CrownOutlined, DownloadOutlined } from '@ant-design/icons';
import { request } from '@/utils'
import { useSearchParams, Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import './index.scss'
import zhCN from 'antd/locale/zh_CN';

const M2d3 = () => {

  const [api, contextHolder] = notification.useNotification();

  const [spinning, setSpinning] = useState(false);

  const [open, setOpen] = useState(false);
  // const [djson, setDjson] = useState(false);
  const [scdblx, setScdblx] = useState();
  const [urlp, setSearch] = useSearchParams()


  const [bangm, setbangm] = useState([]);

  const [rzmsg, setRzmsg] = useState([]);
  useEffect(() => {
    async function getau() {
      const res = await request.post('/pms_list')
      setbangm(res.rescode)
      setRzmsg({ rzdtime: res.rzdtime, ticks: res.ticks })
    }

    getau()

  }, [urlp])






  const [openadcp, setOpenadcp] = useState(false);
  const showDrawer = () => {
    setOpenadcp(true);
  };
  const onClose = () => {
    setOpenadcp(false);
  };
  const [dbxd] = Form.useForm();
  const [adcp] = Form.useForm();


  const onfdbxd = async (e) => { // 数据下单
    setOpen(false)
    setSpinning(true);
    const sxd = await request.post('/p_dbxd', e)
    sxd.return_code  &&  setSpinning(false);
    sxd.return_code==='success' && message.success(sxd.return_msg)//提示


    sxd.enough==='N' &&  api.warning({
      message: '异常提示',
      description:
        <>   余额不足或者未开通对应产品
          <Link to={'/m'} >去购买</Link> </>,
      showProgress: true,
      pauseOnHover: true,
    });
    console.log(sxd)
  }
  const cgg = (e) => { setScdblx(e) } //更新选择的数据类型值

  const onFinishadcp = async (v) => {
    const resadcp = await request.post('/ad_pms', v)

    if (resadcp.return_code === "success") {
      setSearch('t=' + resadcp.times)//刷新json
      message.success(resadcp.return_msg)//提示
      adcp.setFieldsValue({ name: '', tags: [] });//清除输入框内容
      setOpenadcp(false);//关闭


    }
    if (resadcp.return_code === "F") {
      message.error(resadcp.return_msg)
    }


  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`检索内容`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            检索
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const ppth = (e) => {
    if (rzmsg.rzdtime < rzmsg.ticks) {
      api.open({
        message: '认证提示',
        description:
          <>   您的账号还没认证或者认证已到期，请先认证！
            <Link to={'/m/M4z2'} >去认证</Link> </>,
        showProgress: true,
        pauseOnHover: true,
      });

    } else {

      setOpen(true)
      cgg()
      dbxd.setFieldsValue({ cpno: e.key,dblx:'',dbnum:'',ggm:'',ggn:'' })

    }

  
  }


  const columns = [

    {
      title: '产品编号',
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '名称',
      dataIndex: 'pname',
      key: 'pname',
      ...getColumnSearchProps('pname'),
    },
    {
      title: '标签',
      key: 'tags',
      ...getColumnSearchProps('tags'),
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),

    },
    {
      title: <Button type="link" onClick={showDrawer}>+ 产品新增</Button>,
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={'/m/M2d6?q=' + record.key} ><Tooltip title="基础版 信息自由添加">基本参数 </Tooltip></Link>
          <Link to={'/m/M2d61?q=' + record.key} ><Tooltip title="基础版 信息自由添加">产品详情 </Tooltip></Link>
          <Link to={'/m/M2d62?q=' + record.key} ><Tooltip title="基础版 信息自由添加">使用说明 </Tooltip></Link>
          <Link to={'/m/M2d7?q=' + record.key} ><Tooltip title="进阶版 样式自由设计"><Button type="primary" icon={<CrownOutlined />}>信息管理</Button></Tooltip></Link>
          {/* <Link to={'/m/M2d8?q=' + record.key} ><Tooltip title="数据下单，数据源订单信息提交，规格、数量" ><Button type="primary" icon={<DownloadOutlined />} id={record.key} onClick={() => ppth(record)}>数据下单</Button></Tooltip></Link> */}
          <Tooltip title="数据下单，数据源订单信息提交，规格、数量" ><Button type="primary" icon={<DownloadOutlined />} id={record.key} onClick={() => ppth(record)}>数据下单</Button></Tooltip>
        </Space>
      ),
    },
  ];














  const [form] = Form.useForm();
  return (
    <>
      {contextHolder}
      <ConfigProvider locale={zhCN} >


        <div
        // style={containerStyle} 
        >

          <Form form={form} component={false}>

            <Table
              // rowSelection={   {type:"checkbox",...rowSelection,}} 
              columns={columns} dataSource={bangm} />

          </Form>


          <Drawer
            title="增加产品"
            placement="bottom"
            closable={false}
            onClose={onClose}
            open={openadcp}
            getContainer={false}
          >


            <Form
              name="adcp"
              labelCol={{
                flex: '110px',
              }}
              labelAlign="left"
              labelWrap
              wrapperCol={{
                flex: 1,
              }}
              onFinish={onFinishadcp}
              form={adcp}
            >
              <Form.Item
                label="产品名称"
                name="pname"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="产品标签"
                name="tags"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >

                <Select
                  mode="tags"
                  style={{
                    width: '100%',
                  }}
                  tokenSeparators={[',']}
                  placeholder='产品标签，方便查询，不对外展示'

                />
              </Form.Item>

              <Form.Item >
                <Button type="primary" htmlType="submit">
                  提 交
                </Button>
              </Form.Item>
            </Form>







          </Drawer>
        </div>




        <Modal
          title="数据生成信息"
          centered
          open={open}
          footer={false}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}

        >

          <Form form={dbxd} onFinish={onfdbxd}>



            <Form.Item label="产品编号" name="cpno"
            >
              <Input disabled />


            </Form.Item>



            <Form.Item
              name="dblx"
              label="数据类型"
              hasFeedback


            >
              <Select
                onChange={cgg}
                options={[
                  {
                    value: 'Single',
                    label: '最终销售单元',
                  },
                  {
                    value: 'Group',
                    label: '箱、垛、包、捆等集合',
                  },
                ]} />


            </Form.Item>
            {/* scdblx */}






            {scdblx === 'Single' && <Form.Item label="规格信息">




              <Space.Compact>
                <Form.Item
                  name='ggm'
                  noStyle

                >
                  <Select className="selectcss" placeholder="请选择" options={[
                    {
                      value: '规格',
                      label: '规格',
                    },
                    {
                      value: '型号',
                      label: '型号',
                    },
                    {
                      value: '净含量',
                      label: '净含量',
                    },
                    {
                      value: '净重',
                      label: '净重',
                    },
                    {
                      value: '尺寸',
                      label: '尺寸',
                    }
                  ]} />


                </Form.Item>
                <Form.Item
                  name='ggn'
                  noStyle

                >
                  <Input
                   className="inputr"
                    placeholder="规格"
                
                  />
                </Form.Item>
              </Space.Compact>


            </Form.Item>}


            <Form.Item label="生产数量" name="dbnum"
            >
              <Input placeholder='1~1000000之间整数' />
            </Form.Item>

            <Form.Item

            >
              <Flex gap="small">
                <Button type="primary" htmlType="submit" block>
                  提交
                </Button>

              </Flex>
            </Form.Item>




          </Form>





        </Modal>


        <Spin spinning={spinning}  tip="数据处理中，请稍等..." fullscreen />

      </ConfigProvider>
    </>

  );
};
export default M2d3;