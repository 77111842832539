import { Menu, Space, Image, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const Fhead = () => {
    const navigate = useNavigate()
    const items = [
        { label: '首页', key: '/', },
        {
            label: <Tooltip title="农资:农药化肥种子 生产运营优惠版" color="green"><a target='blank' href='http://www.ddspp.com/v2/' style={{ color: 'green', fontWeight: 'bolder', fontSize: "18px" }}>惠农版</a></Tooltip>,
            key: '/#1',
        },
        {
            label: '可信链',
            key: '/x',
        },
        {
            label: '产品中心',
            key: '/menu1',
        },
        {
            label: '合作计划',
            key: '/menu4',
        },
    ];






    const [current, setCurrent] = useState('mail');
    const onClickm = (e) => {
        setCurrent(e.key);
        navigate(e.key, { replace: true })

    };
    const url = 'http://www.ddspp.com/api/img/v3_logo_m.png';

    const muclass = { background: "transparent", float: "right" };


    return <>
        <Space className='fhead'>
            <Image
                width={258}
                src={url}
                alt="avatar"
                preview={false}
                className='himg'
            />

            <Menu style={muclass} onClick={onClickm} selectedKeys={[current]} mode="horizontal" items={items} className='hmenu' />
        </Space>
        {/* http://d.ddspp.com:30123/1000_1734531103#sc */}

    </>


}

export default Fhead