import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchLogin } from '@/store/modules/user';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const Apwjlogin = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onFinish = async (values) => {
    await dispatch(fetchLogin(values))
    navigate('/v3')
  }




  return <><Form onFinish={onFinish}
    validateTrigger="onBlur"
  >
    <Form.Item
      name="username"
      rules={[
        { required: true, message: '请输入用户名!' },
        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号!' }

      ]}
    >
      <Input placeholder="请输入用户名!" autoComplete="true" />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[{ required: true, message: '请输入密码!' }]}
    >
      <Input.Password

        type="password"
        placeholder="请输入密码!"
        autoComplete="true"
      />
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit" block={true} >
        登   录
      </Button>


    </Form.Item>
  </Form>




  </>

}
export default Apwjlogin