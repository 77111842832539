import React, { useEffect, useState } from 'react';
import { WechatFilled, SoundFilled, LaptopOutlined, WechatWorkOutlined, PhoneFilled } from '@ant-design/icons';
import {
  Card, List, Tooltip, Typography, Space, Popover,
  Image,
  FloatButton, QRCode, Input, Col, Row, Tabs, Modal
} from 'antd';
import './index.scss';
import Apwjreg from './_reg'
import Apwjlogin from './_login'


const { TextArea } = Input;

const { Text } = Typography;


const Fbody = () => {


  const [modal2Open, setModal2Open] = useState(false);

  const [text, setText] = useState('http://www.ddspp.com/');
  useEffect(() => { }, [text])

  const data = [
    {
      title: <><SoundFilled />  印刷喷码注意事项</>, content: <div className='con_sone'>辛苦绝对是，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克</div>
    },
    {
      title: <><SoundFilled /> 印刷喷码注意事项</>, content: <div className='con_sone'>辛苦绝对是，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克</div>
    },
    {
      title: <><SoundFilled /> 印刷喷码注意事项</>, content: <div className='con_sone'>辛苦绝对是，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克</div>
    },
  ];

  const data2 = [

    {
      title: <Tooltip title="开发中...农资:农药化肥种子 生产运营优惠版" placement="bottomLeft" color="blue"><div className='titlecs'>惠农版</div></Tooltip>,
      extra: <Tooltip title="开发中...登录" placement="bottom" color="blue"><LaptopOutlined style={{ fontSize: '2em', color: '#4096ff', }} /></Tooltip>,
      content: <div className='con_one'>
        <Space direction="vertical" size="small">
          <Text>适用农药、化肥、种子生产经营者使用的平台系统。</Text>
          <Text>数据量不设限，单批次下载可超百万；保存更久，企业自主删除。</Text>
          <Text>支持营销活动，奖池灵活，按需充值，不压资金。支持验证互动，私域引流(关注公众号、私域电商成交等)。</Text>
        </Space>


      </div>
    },
    {
      title: <Tooltip title="通用版" placement="bottomLeft" color="blue"><div className='titlecs'>无界版</div></Tooltip>,
      extra: <Tooltip title="登录" placement="bottom" color="blue"><LaptopOutlined style={{ fontSize: '2em', color: '#4096ff', }} onClick={() => setModal2Open(true)} /></Tooltip>,
      content: <div className='con_one'>
        <Space direction="vertical">
          <Text>适用于无特殊要求的一般类商品。</Text>
          <Text>功能模块化，按需选购。商品全生命周期追溯，多场景、多主体信息互动。</Text>
          <Text>支持可信数据链(可信区块链技术)，去中心化、透明公开算法，是打造口碑得力助手。</Text>

        </Space>

      </div>
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };
  const ydata = [
    { title: '易实施', description: <div className='smclass'>易批量工业化应用到实际生产、运营中。</div>, },
    { title: '易识别', description: <div className='smclass'>易在生产、运营、客户鉴别中使用，易分辨公示内容真假、鉴别主体真假。</div>, },
    { title: '易互动', description: <div className='smclass'>易购置绑定、真假鉴别留据、维保售后互动。</div>, },
  ];
  const ndata = [
    { title: '难仿造', description: <div className='smclass'>推荐使用隐藏验证码、隐形材料或者工艺增加仿造难度。</div>, },
    { title: '难破解', description: <div className='smclass'>数据规则应使用有序无规则，防止按序被仿造。</div>, },
    { title: '难提取', description: <div className='smclass'>根据包装生产工艺运用结构使用隐藏效验、特殊材料的隐性喷印[符号、文字、图案等]、私有算法的加密编码图案、自研私有字体、不可逆生产工艺生产的物理图案图像。</div>, },
  ];

  const items = [
    {
      key: '1',
      label: <Image
        width={100}
        src="http://d.ddspp.com:30123/1000_1734524170#sc"
        preview={false}
      />,
      children: <List
        itemLayout="horizontal"
        dataSource={ydata}
        renderItem={(item, index) => (
          <List.Item key={`L${index}`}>
            <List.Item.Meta
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />,
    },
    {
      key: '2',
      label: <Image
        width={100}
        src="http://d.ddspp.com:30123/1000_1734524165#sc"
        preview={false}
      />,
      children: <List
        itemLayout="horizontal"
        dataSource={ndata}
        renderItem={(item, index) => (
          <List.Item key={`L${index}`}>
            <List.Item.Meta
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />,
    },
  ];


  const mtpl = { 'margin': "0px 0px 10px", }

  return <>


    <div
      className='fbodym'
    >



      <div className='btfontcss'>二维码防伪追溯的本质是产品运营数字化,数字化的基础是一物一码.</div>


      <Card
        style={mtpl}
      >  <Row>
          <Col span={4}
            className='nrcenter'
          >
            <QRCode value={text ||
              'http://www.ddspp.com'}
            />
          </Col>
          <Col span={20} >
            <TextArea
              rows={7}
              placeholder="http://www.ddspp.com"
              maxLength={80}

              value={text}
              onChange={(e) => { setText(e.target.value) }
              }
              showCount
            />
          </Col>
        </Row></Card>


      <List
        grid={{
          gutter: 16,
          column: 2,
        }}
        split={false}
        dataSource={data2}
        renderItem={(item) => (
          <List.Item>
            <Card title={item.title} extra={item.extra}>{item.content}</Card>
          </List.Item>
        )}
      />



      <Card title={<div className='titlecs'>防伪追溯应遵循原则</div>} style={{ marginBottom: '10px' }}>
        <Tabs defaultActiveKey="1" tabPosition='left' items={items} onChange={onChange} style={{ marginBottom: '10px', alignItems: "left", textAlign: "left" }} />
      </Card>




      <Modal
        name='无界'
        closable={false}
        centered
        footer={null}
        open={modal2Open}
        onCancel={() => setModal2Open(false)}

      >
        <Tabs
          defaultActiveKey="1"
          centered

          items={[{ label: '登录', key: 'm01', children: <Apwjlogin />, },
          { label: '注册', key: 'm02', children: <Apwjreg />, }]}
        />
      </Modal>












      <List
        grid={{
          gutter: 16,
          column: 3,
        }}

        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Card title={item.title} style={{ marginBottom: '10px', alignItems: "left", textAlign: "left" }} >{item.content}</Card>
          </List.Item>
        )}
      />
    </div>



    <FloatButton.Group
      shape="square"
      style={{
        insetInlineEnd: 94,
      }}
    >
      <FloatButton icon={<WechatWorkOutlined style={{
        color: '#389e0d',
      }} />}

        href="https://work.weixin.qq.com/kfid/kfcfeff9d9a4dfb1ce0"
        target="_blank"
        className='wxkf'
      />
{/* //  http://d.ddspp.com:30123/1000_1735720207#sc */}
      <Popover placement="left" content={<Image
    width={80}
    src="http://d.ddspp.com:30123/1000_1735720207#sc"
    preview={false}
  />}><FloatButton icon={<WechatFilled style={{
        color: '#389e0d',
      }} />} /></Popover>
      <Popover placement="left" content='13733826017' ><FloatButton icon={<PhoneFilled style={{
        color: '#389e0d',
      }}/>} /></Popover>

      {/* <FloatButton.BackTop visibilityHeight={0} /> */}
    </FloatButton.Group>



  </>


}

export default Fbody