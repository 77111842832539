import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Provider } from 'react-redux';
import store from './store';
import { ConfigProvider } from 'antd';
import { registerConfig as config } from './editor-config'
import 'normalize.css'
import zhCN from 'antd/locale/zh_CN';

const config1 = config;
export const Conf = createContext({});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ConfigProvider locale={zhCN}>
        <Conf.Provider value={config1} >

            <Provider store={store}>

                <RouterProvider router={router} />
            </Provider>


        </Conf.Provider>
    </ConfigProvider>





);

