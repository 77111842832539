import React, { useEffect, useState } from 'react';
import type { ProSettings } from '@ant-design/pro-components';
import { PageContainer, ProCard, ProLayout } from '@ant-design/pro-components';
import { Drawer, message, Tooltip } from 'antd';
import { IdcardOutlined, PoweroffOutlined ,DatabaseOutlined,HomeOutlined,ToolOutlined, DesktopOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import {Zja,Apay} from './_kzj';
import { request, removeToken } from '../../utils/index';


const Abm = () => {

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate()
  const [uname, setUname] = useState("用户名");
  const [bangm, setbangm] = React.useState<string>();
  const [sysz, setSys] = useState();

  // 安全退出
  const onConfirm = () => { localStorage.removeItem('scusername'); localStorage.removeItem('token_key'); navigate('/'); }


  const bjztq = { smsg1: 'success', smsg2: 'false' }
  useEffect(() => {
    async function getau() {
      const resg: any = await request.get('/authalpha')
      const bjzt = resg.return_code
      const alpha = resg.alpha
      setbangm(alpha)
      if (bjzt === bjztq.smsg2) {
        messageApi.open({ type: 'error', content: '登录超时', }); removeToken();
        
          return   setTimeout(()=>{navigate('/')},500)
      }
      resg.u_zqjson && setSys(resg.u_zqjson.sys)
      setUname(resg.u_name)
    }
    getau()
  }, [bjztq.smsg2, bjztq.smsg1, navigate, messageApi])





////////////////////菜单配置start
  const defaultProps= {
    route: {
      path: '/v3',
      routes: [
        {
          path: '/v3',
          name: '欢迎',
          icon: <HomeOutlined />,
          component: './v3',
        },
        {
          path: 'v3/pdt',
          name: '生产数据资料',
          icon: <DatabaseOutlined />,
          component: './v3',
          routes: [
            {
              path: 'sup',
              name: '产品档案',
              component: './v3',
              routes: [
                {
                  path: 'plist',
                  name: '产品列表',  
                  component: './v3',
                },
                {
                  path: 'intro',
                  name: '企业介绍(通用)',
                  component: './v3',
                },
              ],
            },
            {
              path: 'redb',
              name: '近日数据',
              component: './v3',
            },
            {
              path: 'adb',
              name: '全部数据',
              component: './v3',
            },
          ],
        },

        sysz === 'Y' &&  {
          name: '内测模块',
          icon: <ToolOutlined />,
          path: 'v3/tc',
          component: './v3',
          routes: [
            {
              path: 'tp',
              name: '列表页面list',
              component: './v3',
              routes: [
                {
                  path: 'p1',
                  name: '测试1',
                  component: './v3',
                },
                {
                  path: 'p2',
                  name: '测试2',
                  component: './v3',
                },
                {
                  path: 'p3',
                  name: '测试3',
                  component: './v3',
                },
              ],
            },
            {
              path: 'tz',
              name: '拖拽页面',
              component: './v3',
            },
            {
              path: 'lst',
              name: '临时测试',
              component: './v3',
            },
          ],
        },
        

        bangm === 'Y' &&  {
          name: '系统管理员',
          icon: <ToolOutlined />,
          path: 'v3/sys',
          component: './v3',
          routes: [
            {
              path: 'msfb',
              name: '消息发布',
              component: './v3',
              routes: [
                {
                  path: 'msp1',
                  name: '发布1',
                  component: './v3',
                },
                {
                  path: 'msp2',
                  name: '发布2',
                  component: './v3',
                },
                {
                  path: 'msp3',
                  name: '发布3',
                  component: './v3',
                },
              ],
            },
            {
              path: 'tcset',
              name: '套餐设置',
              component: './v3',
            },

          ],
        },

        {
          name: '用户管理中心',
          icon: <DesktopOutlined />,
          path: 'v3/uc',
          component: './v3',
          routes: [
            {
              path: 'un',
              name: '账号信息',
              component: './v3',
              routes: [
                {
                  path: 'uname',
                  name: '实名认证',
                  component: './v3',
                },
                {
                  path: 'uasef',
                  name: '账号安全',
                  component: './v3',
                },
                {
                  path: 'uauth',
                  name: '身份与权限',
                  component: './v3',
                },
                {
                  path: 'usa',
                  name: '子账号管理',
                  component: './v3',
                },
              ],
            },
            {
              path: 'oai',
              name: '订单与发票',
              component: './v3',
              routes: [
                {
                  path: 'cz',
                  name: '充值记录',
                  component: './v3',
                },
                {
                  path: 'xf',
                  name: '消费记录',
                  component: './v3',
                },
                {
                  path: 'kp',
                  name: '开票记录',
                  component: './v3',
                },
              ], 
            },
            {
              path: 'aap',
              name: '地址和联系人',
              component: './v3',
            },
          ],
        },
     
      ],
    },
    appList: [
      {
        icon: 'http://d.ddspp.com:30123/1001_1731935981#sc',
        title: 'Sheng Cong Wang ',
        desc: '胜聪网-可信链 备用',
        url: 'http://www.ddspp.com',
        target: '_blank',//可注释
      },
      {
        icon: 'http://d.ddspp.com:30123/1001_1731935981#sc',
        title: 'Sheng Cong Wang ',
        desc: '胜聪网-可信链 备用',
        url: 'http://www.ddspp.com',
        target: '_blank',
      },
      {
        icon: 'http://d.ddspp.com:30123/1001_1731935981#sc',
        title: 'Sheng Cong Wang ',
        desc: '胜聪网-可信链 备用',
        url: 'http://www.ddspp.com',
        target: '_blank',
      },
      
    ],
  };
////////////////////菜单配置end



  const settings = useState<Partial<ProSettings> | undefined>({ layout: 'side', });
  const [pathname, setPathname] = useState('/v3');
  //////////////////////////////


  const [open, setOpen] = useState(false);
  const showLargeDrawer = () => { setOpen(true) }
  const onClose = () => { setOpen(false) }




  return (<>
    {contextHolder}
    <div
      style={{
        height: '100vh',
      }}
    >

      <ProLayout
        title="胜聪数字化中心"
        logo="http://d.ddspp.com:30123/1001_1731935981#sc"
        siderWidth={256}

        {...defaultProps}
        location={{
          pathname,
        }}
        menu={{
          type: 'group',
        }}
        avatarProps={{
          src: <IdcardOutlined style={{ color: '#434343' }} onClick={showLargeDrawer} />,
          title: <div onClick={showLargeDrawer}>{uname}</div>,
        }}


        actionsRender={(props) => {
          if (props.isMobile) return [];
          return [
            <Tooltip placement="top" title='安全退出' ><PoweroffOutlined key="PoweroffOutlined" onClick={onConfirm} />
            </Tooltip>
            ,
          ];
        }}

        menuItemRender={(item, dom) => (
          <div
            onClick={() => { const sedpath:any = item.path
              setPathname(item.path || '/v3');
              navigate(sedpath);
              console.log(item.name,sedpath)
            }}
          >
            {dom}
          </div>
        )}
        {...settings}
      >
        <PageContainer header={{ title: false }}  >
          <ProCard
            style={{
              height: '100vh',
              minHeight: 800,
            }}
          >
            <Outlet></Outlet>


          </ProCard>
        </PageContainer>
      </ProLayout>








      <Drawer
        title="账号信息"
        placement="right"
        size="large"
        onClose={onClose}
        className='zhxx'
        open={open}

      >
       
         <Zja/> 
        <Apay/>
      </Drawer>




    </div>
  </>
  );
};

export default Abm;