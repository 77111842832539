import React, {  useState ,useEffect} from 'react';

import {  Input, Form, QRCode, Result, Radio, message} from 'antd';


import { request } from '@/utils'


const Apay=()=>{
    const [pdno, setPdno] = useState();//订单号码
    const [zurl1, setZurl1] = useState();//二维码地址内容
    const [zicon, setZicon] = useState();//码眼链接
    const [pcode, setpcode] =useState(false);//显示二维码图片开关
    const [pzt, setpzt] = useState(false);//支付状态
    const [pc, setpc] = useState(false);//查询支付结果  轮询状态
  
    const [paym] = Form.useForm();
    const payje = { payje: Form.useWatch('payje', paym) };
  
  
    const xzpay = (c) => {
      const payto = { payto: c.target.value };
  
  
  
      const vxdz = Object.assign(payje, payto);
      if (payje.payje === undefined || payje.payje === '' || payje.payje <= 0) { message.error('请填写正确金额') } else { Tpay(vxdz, pc) }
    };
  
  
  
    const Tpay = async (props, zicon, zurl1, pcode, pzt, pc, pdno) => {
      pc = true
      const respaym = await request.post('/p_pay', props)
      respaym.return_code === 'success' && setpc(pc)
      pdno = { ddno: respaym.ddno }
      setPdno(pdno)
      zurl1 = respaym.zurl
      zicon = respaym.icon
      pcode = true
  
      pc && setpcode(pcode)
  
      setZurl1(zurl1)
      setZicon(zicon)
      
      setpcode(pcode)
      setpzt(pzt)
  
  
  
    }
  



    useEffect(() => {
        if (pdno !== undefined) {
          const aat = setInterval(async () => {
            const cpz = await request.post('/p_payc', pdno) // 查支付状态
            if (cpz.ztcode === 'Y') {

  
              setpzt(true)
              setpcode(false)
              setPdno(undefined)
    
              setTimeout(() => {
                setpzt(false)
                setpc(false)
              }, 3000) //付款成功标志 
    
    
    
    
            }
    
    
          }, 5000)
    
          return () => { clearInterval(aat) }
    
        }
      }, [pdno])


return(
<>
<Form
            name="paym"
            form={paym}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"

          >
            <Form.Item
              label="账号充值金额"
              name="payje"
              rules={[
                {
                  required: true,
                  message: '请填写正确的金额!',
                },
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请填写正确的金额!' }
              ]}
            >
              <Input
                prefix="￥" suffix="RMB"
                autoComplete="true" />

            </Form.Item>


            <Form.Item
              label="支付方式"
              name="payto"
              className='payto'
              wrapperCol={{
                offset: 2,
                span: 16,
              }}
            >
              <Radio.Group onChange={payje === undefined ? '' : xzpay} >  
                <Radio value="WX" id='WX'>微信</Radio>
                <Radio value="ZFB" id='ZFB'>支付宝</Radio>

              </Radio.Group>
            </Form.Item>

            {pcode && <Form.Item className='payto'
              wrapperCol={{
                offset: 10,
                span: 16,
              }}
            >
              {zurl1 && <QRCode
                errorLevel="H"

                value={zurl1}
                icon={zicon}
              
              />}

            </Form.Item>}


          </Form>
          {pzt && <Result status="success" title="支付成功!" />}
</>
)

}
export default Apay