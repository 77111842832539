import React from 'react';
import {Result ,Typography } from 'antd';
const { Link } = Typography;
const NotFound = () => (
  <Result
    status="404"
    // title="404"
    subTitle={<>抱歉，您访问的资源不存在! {<Link href="/">返回主页</Link>}</>}
  />
);
export default NotFound;